'use client';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  icon?: any;
  children?: any;
  onClick?: () => void;
  classes?: {
    root?: string;
    text?: string;
    icon?: string;
  }
}

const FunctionBtn = (props: Props) => {
  const { icon, children, onClick, classes } = props;
  return (
    <div onClick={onClick} className={`m-1 flex justify-between items-center go-btn text-white p-1 px-2 rounded-[30px] hover:cursor-pointer ${classes?.root}`}>
      <div className={`text-[15px] ${classes?.text}`}>{children}</div>
      {icon?
        <FontAwesomeIcon icon={icon} className={`ml-1 text-[2rem] lg:w-[1.3rem] w-[1rem] fa-solid inline-block pr-2 ${classes?.icon}`} />:<></>
      }
    </div>
      
  );
};

export default FunctionBtn;