import React, { createContext, useContext, useState } from 'react';

type IAccountModal = {
  open: boolean;
  tab: string;
};

type AccountModalProps = {
  accountModal: IAccountModal;
  setAccountModal: (accountModal: IAccountModal) => void
}

export const AccountModalContext = createContext<AccountModalProps>({
  accountModal: {
    open: false,
    tab: 'Profile',
  },
  setAccountModal: () => null,
});
export const useAccountModal = () => useContext(AccountModalContext);

export const AccountModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [accountModal, setAccountModal] = useState({
    open: false,
    tab: 'Profile',
  });

  return (
    <AccountModalContext.Provider value={{ accountModal, setAccountModal }}>
      {children}
    </AccountModalContext.Provider>
  );
};