export enum Plan {
  STARTER = 'STARTER',
  PRO = 'PRO',
  BUSINESS = 'BUSINESS',
}

export enum SubscriptionStatus {
  // User clicked checkout button in the frontend and initiated stripe checkout page.
  INITIATED = 'INITIATED',

  // User paid subscription
  PAID = 'PAID',

  // Subscription expired and user didn't pay yet.
  EXPIRED = 'EXPIRED',
}

export enum SearchType {
  CUSTOM_SEARCH = 'CUSTOM_SEARCH',
  AI_SEARCH = 'AI_SEARCH',
}
