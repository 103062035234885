import axios, { AxiosResponse } from 'axios';
import { getCookie, setCookie } from 'cookies-next';

import { navigations } from '@/app/constants';
import { generateUniqueID } from '@/helpers';
export const API_URL = process.env.NEXT_PUBLIC_API_PATH;
export const REQUEST_API_URL = `${API_URL}/api`;

let deviceToken = getCookie('device');
if (!deviceToken) {
  deviceToken = generateUniqueID();
  setCookie('device', deviceToken);
}
axios.defaults.headers.common['device'] = deviceToken;

axios.interceptors.request.use(
  async (config: any) => {
    config.baseURL = REQUEST_API_URL;

    const token = getCookie('cq_token');
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    config.headers['x-origin'] = 'CHAINQ'; // It is necessary to call stakeseeker api.

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (res: AxiosResponse) => res,
  (err) => {
    err.message = 'Network Error';
    if (
      err.message &&
      !err.response &&
      window.location.pathname !== navigations.SERVER_ERROR
    ) {
      window.location.href = navigations.SERVER_ERROR;
    } else {
      throw err;
    }
  }
);

export default axios;
