'use client';
import { ModalBody, ModalContent } from '@nextui-org/react';
import { t } from 'i18next';

import CommonModal from '../CommonModal';

type Props = {
  isOpen: boolean;
  onSuccess: () => void;
  onClose?: () => void;
  message?: string;
  buttonLabel?: string;
};

const ResultModal = ({ isOpen, onSuccess, onClose, message, buttonLabel }: Props) => {
  return (
    <CommonModal onESC={onClose ?? onSuccess} isOpen={isOpen}>
      <ModalContent>
        <ModalBody>
          {/*<Modal isOpen={isOpen} close={onClose ?? onSuccess} size='sm'>*/}
          <div className='flex flex-col items-center py-4'>
            <p className='mb-8 font-bold text-md'>{message}</p>
            <button
              className="flex justify-center w-full md:w-[40%] items-center go-btn text-white p-2.5 rounded-[30px]"
              onClick={onSuccess}
            >
              <p className='text-[16px]'>{buttonLabel || t('OK')}</p>
            </button>
          </div>
        </ModalBody>
      </ModalContent>
    </CommonModal>
  );
};

export default ResultModal;
