'use client';
import { useEffect } from 'react';

import { Modal } from '@nextui-org/react';

const CommonModal = ({ onESC, ...props }: any) => {
  const handleESC = (e: KeyboardEvent) => {
    if(e.keyCode == 27){
      onESC();
    }
  };

  useEffect(()=>{
    document.addEventListener('keyup', handleESC);
    return () => {
      document.removeEventListener('keyup', handleESC);
    };
  },[]);

  return (
    <Modal placement="center" {...props}>
      {props.children}
    </Modal>
  );
};

export default CommonModal;
