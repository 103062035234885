import { faArrowRightFromBracket, faArrowRightArrowLeft, faArrowRightToBracket, faPeopleArrows, faPiggyBank, faArrowsRotate, faWallet, faChain, faSearch, faFilter, faSortAmountDesc } from '@fortawesome/free-solid-svg-icons';

import { IOption, ISearchForOption, TxTypeToIconMap } from '@/types';
// import { Styles } from 'react-joyride';

export const chainOptions: IOption[] = [
  {
    label: 'Cosmos',
    value: 'COSMOS',
    isDisabled: false,
  },
  {
    label: 'Additional chains coming soon',
    value: 'COMING_SOON',
    isDisabled: true,
  },
];

export const searchForOptions: ISearchForOption = {
  'COSMOS': [
    {
      label: 'Transactions',
      value: 'Transactions',
      faIcon: faArrowRightArrowLeft,
      subMenu: [
        {
          label: 'Send and Receive',
          value: 'Transfer',
          faIcon: faPeopleArrows,
          checked: false,
        },
        {
          label: 'Rewards',
          value: 'ClaimReward',
          faIcon: faPiggyBank,
          checked: false,
          isDisabled: false,
        },
        {
          label: 'Delegations',
          value: 'Delegate',
          faIcon: faArrowRightToBracket,
          checked: false,
        },
        {
          label: 'Undelegations',
          value: 'Undelegate',
          faIcon    : faArrowRightFromBracket,
          checked: false,
        },
        {
          label: 'Redelegations',
          value: 'Redelegate',
          faIcon: faArrowsRotate,
          checked: false,
        }
      ]
    },
    {
      label: 'Wallets',
      value: 'Wallets',
      faIcon: faWallet,
      isDisabled: true,
    },
  ],
  'BITCOIN': [
    {
      label: 'Send and Receive',
      value: 'Transfer',
      faIcon: faPeopleArrows,
    },
    {
      label: 'Wallets',
      value: 'Wallets',
      faIcon: faWallet,
    }
  ]
};

export const txTypeToIconMap: TxTypeToIconMap = {
  'Transfer': faPeopleArrows,
  'Delegate': faArrowRightToBracket,
  'Undelegate':  faArrowRightFromBracket,
  'Redelegate': faArrowsRotate,
  'ClaimReward': faPiggyBank,
  'Wallets': faWallet,
};

export const STATUSES = {
  INITIAL: 0,
  PENDING: 1,
  FULFILLED: 2,
  REJECTED: 3,
};

export const navigations = {
  LOGIN: '/login',
  CONFIRM_DEVICE: '/confirm-device',
  AUTH_CODE: '/auth-code',
  CONFIRM_EMAIL: '/confirm-email',
  AI_ANAYTICS: '/ai-analytics',
  CUSTOM_SEARCH: '/custom-search',
  FORGET_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  REGISTER: '/register',
  SERVER_ERROR: '/server-error',
};

export const keyboardKeys = {
  space: 'Space',
};

export const currencySymbol = {
  usd: '$',
  euro: '€',
};

export const hiddenCryptoSymbol = ['USD', 'USD.HOLD'];

export const DURATIONS = {
  daily: { label: '1D' },
  weekly: { label: '1W' },
  monthly: { label: '1M' },
  quarter: { label: '3M' },
  semiyearly: { label: '6M' },
  yearly: { label: '1Y' },
};

export const RECOMMENDATIONS = [
  { label: 'Google', value: 'Google' },
  { label: 'Youtube', value: 'Youtube' },
  { label: 'Twitter', value: 'Twitter' },
  { label: 'Reddit', value: 'Reddit' },
  { label: 'Discord', value: 'Discord' },
  { label: 'LinkedIn', value: 'LinkedIn' },
  { label: 'Email', value: 'Email' },
  { label: 'Website', value: 'Website' },
  { label: 'Other', value: 'Other' },
];

export const cropperSize = 300;
export const precision = 100;

export const TEXT_CONTENTS = {
  generatingChartData:
    'We are generating the chart data. Please refresh this page after a few minutes.',
  generatingBugLogs:
    'Thank You. We are generating debug logs that will help us troubleshoot the problem.',
  comingSoon: 'Coming Soon',
  saveExchangeLabel: 'Do you want to change current exchange label?',
  exchangeLabelUpdateSuccess: 'Exchange label updated successfully',
  exchangeLabelUpdateFailed: 'Exchange label updated failed',
  question: {
    avatar: 'Do you want to delete your profile picture?',
    account: 'Do you want to delete your account?',
    reset: 'Are you sure? This will reset all data. It may take time to generate new data.',
    generate: 'Are you sure?',
    signOut: 'Are you sure you want to logout?',
  },
  loading: {
    generate: 'Generating Debug Logs',
    reset: 'Resetting User Data',
    account: 'Deleting Account',
    avatar: 'Deleting Avatar',
    update: 'Updating Profile',
  },

  portfolioValue: 'Portfolio Value',
  totalBalance: 'Total Balance',
  stakedTitle: 'Staked',
  availableBalance: 'Available Balance',
  rewards: 'Rewards',
};

export const TABS = [
  '/ai-analytics',
  '/custom-search',
];

export const CUSTOM_SEARCH_CONTENTS = [
  {
    faIcon: faChain,
    title: '1. Choose a Blockchain',
    content: 'Select the blockchain network that you wiash to query with custom search'
  },
  {
    faIcon: faSearch,
    title: '2. Define Your Search',
    content: 'Specify what you\'re searching for by choosing from a range of options to refine your query.'
  },
  {
    faIcon: faFilter,
    title: '3. Tailor Your Searh',
    content: 'Customize your search by filtering results based on criteria like Date Range, Transaction Wallets, Amounts, and more.'
  },
  {
    faIcon: faSortAmountDesc,
    title: '4. Sort and Organize',
    content: 'Once you hit "Go", effortlessly organize your findings by Amount, Fees, or Date.'
  },
];

export const USER_FEEDBACK_QUESTION = 'Help us fine-tune ChainQ. Did ChainQ answer your question correctly?';

export const FEEDBACK_CONTENTS = [
  'The result were incorrect.',
  'The search took too long.',
  'The search didn\'t work at all.',
];